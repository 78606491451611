import React from "react";
import {
    Accordion,
    AccordionActions,  
    AccordionDetails, Box, FormControlLabel, Paper, Radio, RadioGroup
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { useStyles } from './album_layout';
import { useTranslation } from 'react-i18next';

import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from "@material-ui/core/styles";

import SchoolIcon from "@material-ui/icons/School";
import CommentIcon from "@material-ui/icons/Comment";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

export const AnswerAccordion = (props: any) => {
  const question = props.question;
  const answers = props.answers;
  const upsertAnswer = props.upsertAnswer;
  const { t } = useTranslation();
  const theme = useTheme();
  
  const [value, setValue] = React.useState<string|null>( null );

  const answers_lookup = answers.reduce(function (obj, a) {
    obj[a.question_id] = a;
    return obj;
  }, {});
  const answer = answers_lookup[question.slug] ? answers_lookup[question.slug] : {id: null, answer_id: null} ;

  React.useEffect(() => {
    if (answer.answer_id && value == null) {
      setValue(answer.answer_id);
    }
  });


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const answer_slug = (event.target as HTMLInputElement).value;
    setValue(answer_slug);
    upsertAnswer(
      question.slug,
      answer_slug,
      answer.id
    );

  };

  if (!question.answers) {
    return null;
  }

  return (
    <RadioGroup
      aria-label="answer"
      name="answer"
      value={value}
      onChange={handleChange}
    >
      {question.answers.map((a: any) => (
        <FormControlLabel
          value={a.slug}
          key={a.slug}
          control={<Radio />}
          label={
            <div>
              {value !== a.slug && (
                <Typography
                  style={{
                    width: "100%",
                    color: theme.palette.text.secondary,
                    paddingBottom: "10px",
                  }}
                >
                  {a.response}
                </Typography>
              )}
              {value === a.slug && (
                <Paper
                  variant="outlined"
                  style={{
                    borderColor: theme.palette.secondary.main,
                    borderLeftWidth: "0px",
                    borderRightWidth: "0px",
                    borderStyle: "dashed",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Typography
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: "1.25em",
                    }}
                  >
                    {a.response}
                    {a.correct.toLowerCase() === "false" && (
                      <CloseIcon style={{ color: "red", height: "1em" }} />
                    )}
                    {a.correct.toLowerCase() === "true" && (
                      <CheckIcon style={{ color: "green", height: "1em" }} />
                    )}
                  </Typography>

                  <Typography
                    color="secondary"
                    style={{
                      fontSize: "1.25em",
                    }}
                  >
                    {a.why}
                  </Typography>
                </Paper>
              )}
            </div>
          }
        />
      ))}
    </RadioGroup>
  );
};