import { commentsByCreatedAt } from "../graphql/queries";

import { API, graphqlOperation, Auth } from "aws-amplify";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography, DialogContent, Dialog, useTheme, DialogActions, Button, TextField, IconButton, ListItemSecondaryAction
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";

import { useTranslation } from 'react-i18next';
import { createComment, updateComment, deleteComment } from '../graphql/mutations';

import { userName, userPicture } from "./authenticator";
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  fonts: {
    fontWeight: "bold",
  },
  inline: {
    display: "inline",
  },
}));


const Comment = (props) => {
  const question = props.question;
  const onClose = props.onClose;
  const classes = useStyles();
  const theme = useTheme();
  const [user, setUser] = React.useState({});
  const [comments, setComments] = React.useState([]);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [refresh, setRefresh] = React.useState("");
  const [error, setError] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const fetch = async () => {
        const evt = await API.graphql({
          query: commentsByCreatedAt,
          variables: { question_id: question.slug },
          authMode: "API_KEY",
        });
        //@ts-ignore
        setComments(evt.data.commentsByCreatedAt.items);
      };
      fetch();

      Auth.currentAuthenticatedUser().then((_user) => {
        setUser(_user);
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [refresh]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  /**
   * Save comment to DB
   */
  const _upsertComment = async (text: string, comment_id?: string) => {
    const quiz_slug = question.quiz_id.split('/')[1];
    const comment = {
      question_id: question.slug,
      quiz_id: quiz_slug,
      user_name: userName(user),
      user_avatar: userPicture(user),
      comment: text,
    };
    try {
      if (comment_id) {
        comment.id = comment_id;
        const result = await API.graphql(
          graphqlOperation(updateComment, {
            input: comment,
            authMode: "AMAZON_COGNITO_USER_POOLS",
          })
        );
      } else {
        const result = await API.graphql(
          graphqlOperation(createComment, {
            input: comment,
            authMode: "AMAZON_COGNITO_USER_POOLS",
          })
        );
      }
      // aggregation.comment_count += 1;
      // setAggregation(aggregation);
      // setPrimitive("comment", "");
      setRefresh(new Date().toISOString());
    } catch (error) {
      console.log(error);
      if (error.errors) {
        setError(error.errors[0].message);
      } else {
        setError(error);
      }
    }
  };

  /**
   * Save comment to DB
   */
  const _deleteComment = async (comment_id: string) => {
    try {
      const result = await API.graphql({
        query: deleteComment,
        variables: { input: { id: comment_id } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      // aggregation.comment_count += -1 ;
      // setAggregation(aggregation);
      setRefresh(new Date().toISOString());
    } catch (error) {
      console.log(error);
      if (error.errors) {
        setError(error.errors[0].message);
      } else if (typeof error === "string") {
        setError(error);
      }
    }
  };

  const DisplayComment = (props) => {
    const comment = props.comment;
    const user = props.user;

    const theme = useTheme();
    const userInitials = comment.user_name
      ?.split(" ")
      .map((n: string) => n[0])
      .join("");
    const isOwner = comment.owner === user?.username;
    const [editing, setEditing] = React.useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    return (
      <React.Fragment>
        {!editing && (
          <ListItem key={comment.id}>
            <ListItemAvatar>
              {comment.user_avatar ? (
                <Avatar alt="avatar" src={comment.user_avatar} />
              ) : (
                <Avatar alt="avatar">{userInitials}</Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography style={{minWidth:"40em"}} color="secondary" className={classes.fonts}>
                  {comment.comment}
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                  >
                    <Moment fromNow style={{ justifyContent: "center" }}>
                      {comment.updatedAt}
                    </Moment>
                  </Typography>
                </>
              }
            />
            <ListItemSecondaryAction>
              {isOwner && (
                <IconButton
                  edge="end"
                  aria-label="delete"
                  color="secondary"
                  onClick={() => _deleteComment(comment.id)}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              )}
              {isOwner && (
                <IconButton
                  edge="end"
                  aria-label="edit"
                  color="secondary"
                  onClick={() => setEditing(true)}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {editing && (
          <ListItem key={comment.id}>
            <UpsertComment comment={comment} />
          </ListItem>
        )}
      </React.Fragment>
    );

  };

  const UpsertComment = (props) => {
    const comment = props.comment;
    const [inputText, setInputText] = React.useState<string>(
      comment ? comment.comment : ""
    );

    const handleInputChange = (_inputText: string) => {
      setInputText(_inputText);
    };

    if (user.username) {
      return (
        <React.Fragment>
          {/* new comment */}
          <TextField
            id="filled-basic"
            multiline
            value={inputText}
            InputProps={{
              style: { color: "white", marginLeft: "4px" },
            }}
            color="secondary"
            variant="outlined"
            placeholder={t("comment-placeholder")}
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
          />
          <IconButton
            aria-label="send"
            color="secondary"
            className={classes.margin}
            disabled={inputText.length === 0}
            onClick={() =>
              _upsertComment(inputText, comment ? comment.id : undefined)
            }
          >
            <SendIcon fontSize="inherit" />
          </IconButton>
        </React.Fragment>
      );
    }
  return (
    <React.Fragment>
      <Typography color="secondary">
        {t('sign-in-to-comment')}
      </Typography>
      
    </React.Fragment>
  );


  };
  //        

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: theme.palette.text.secondary,
        },
      }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogContent style={{ overflow: "hidden" }}>
        <List className={classes.root}>
          {/* list of comments */}
          {comments.map((comment) => {
            return (
              <DisplayComment key={comment.id} comment={comment} user={user} />
            );
          })}
          {/* new comment */}
          <ListItem key="new-comment">
            <UpsertComment />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => handleClose()}
        >
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

 


export default Comment;


