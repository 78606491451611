
import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
import { Link } from "gatsby";
import Img from "gatsby-image";
import AlbumLayout, { useStyles } from "./album_layout";
import { AnswerAccordion } from './answer_accordian';
import { Breadcrumbs, IconButton } from "@material-ui/core";
import CommentIcon from '@material-ui/icons/Comment';
import Comment from './comments'

import { answersByQuizId } from "../graphql/queries";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { updateAnswer, createAnswer } from "../graphql/mutations";

import uuid from "react-uuid";

const Quiz = ({ pageContext }) => {
  const quiz = pageContext.quiz;
  const persona = pageContext.persona;
  const pagePath = pageContext.pagePath;

  const { t } = useTranslation();
  const classes = useStyles();

  console.assert(quiz, "quiz not defined");
  console.assert(
    persona,
    `persona ${quiz.persona_id} not defined in ${quiz.id}`
  );

  const [showComments, setShowComments] = React.useState<string | null>(null);
  const [answers, setAnswers] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [refresh, setRefresh] = React.useState("");
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [localAnswers, setLocalAnswers] = React.useState( false );
  const [percentageComplete, setPercentageComplete] = React.useState(0.0);
  const [grade, setGrade] = React.useState(0.0);
  const [questionsRemaining, setQuestionsRemaining] = React.useState(0);


  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      _calculateGrade();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [answers]);  

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {

      const fetchLocal = async () => {
        setAnswers(JSON.parse(localStorage.getItem(`answers-${quiz.slug}`) || "[]"));
        setLocalAnswers(true);
      };

      const fetch = async () => {
        const evt = await API.graphql({
          query: answersByQuizId,
          variables: { quiz_id: quiz.slug },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        //@ts-ignore
        setAnswers(evt.data.answersByQuizId.items);
      };

      if (user.username) {
        if (localAnswers && answers.length > 0) {
          answers.forEach((a:any) => _upsertAnswer(a.question_id, a.answer_id, a.id));
        }
        fetch();
      } else {
        fetchLocal()
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [user]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Auth.currentAuthenticatedUser().then((_user) => {
        setUser(_user);
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);


  const _upsertAnswerLocal = async (answer:any) => {
    const items = JSON.parse(localStorage.getItem(`answers-${quiz.slug}`) || "[]");

    if (!answer.id) {
      answer.id = uuid();
    }
    const updated_items = items.filter((i: any) => i.id !== answer.id);
    updated_items.push(answer)
    localStorage.setItem(`answers-${quiz.slug}`, JSON.stringify(updated_items));
  }

  const _calculateGrade = () => {
    const answers_lookup = answers.reduce(function (obj, a) {
      obj[a.question_id] = a;
      return obj;
    }, {});
    const questions_remaining =
      quiz.questions
        .map((q) => (answers_lookup[q.slug] ? answers_lookup[q.slug] : null))
        .filter((a) => a === null).length ;
    const percent_complete =
      (quiz.questions.map((q) =>
        answers_lookup[q.slug] ? answers_lookup[q.slug] : null
      ).filter((a) => a).length /
        quiz.questions.length) *
      100;
    const percent_grade =
      (quiz.questions
        .map((q) => {
          const a = answers_lookup[q.slug];
          var correct = false;
          if (a) {
            q.answers.forEach((qa) => {
              if (
                qa.slug === a.answer_id &&
                qa.correct.toLowerCase() === "true"
              ) {
                correct = true;
              }
            });
          }
          return correct;
        })
        .filter((tf) => tf).length /
        quiz.questions.length) *
      100;
    setPercentageComplete(percent_complete);
    setGrade(percent_grade);
    setQuestionsRemaining(questions_remaining);
  } ;


  /**
   * Save comment to server DB
   */
  const _upsertAnswer = async (question_id: string, answer_id: string, id?:string) => {
    
    const answer = {
      question_id: question_id,
      quiz_id: quiz.slug,
      answer_id: answer_id,
    };
    if (!user.username) {
      _upsertAnswerLocal(answer);
      answers.push(answer);
      _calculateGrade();
      return;
    }    
    try {
      if (id) {
        answer.id = id;
        const result = await API.graphql(
          graphqlOperation(updateAnswer, {
            input: answer,
            authMode: "AMAZON_COGNITO_USER_POOLS",
          })
        );
      } else {
        const result = await API.graphql(
          graphqlOperation(createAnswer, {
            input: answer,
            authMode: "AMAZON_COGNITO_USER_POOLS",
          })
        );
      }
      setRefresh(new Date().toISOString());
    } catch (error) {
      console.log(error);
      if (error.errors) {
        setError(error.errors[0].message);
      } else {
        setError(error);
      }
    }
    _calculateGrade();
  };

  const onCommentDialogClose = () => {
    setShowComments(null);
  };


  const _breadCrumbs = () => {
    var togo = `${t("to-go")}: ${questionsRemaining}`;
    if (questionsRemaining === 0) {
      togo = t("done")
    }
    return (
    <Breadcrumbs className={classes.breadCrumb} aria-label="breadcrumb">
      <Link
        style={{ textDecoration: "none" }}
        color="inherit"
        to={`/personas/${persona.language}`}
      >
        <Typography color="secondary">{t("home")}</Typography>
      </Link>
      <Link
        style={{ textDecoration: "none" }}
        to={`/personas/${persona.language}/${persona.slug}`}
      >
        <Typography color="secondary">{persona.name}</Typography>
      </Link>
      <Typography color="secondary">{quiz.name}</Typography>
      <Typography color="secondary">{`(${t("grade")}:${grade.toFixed(0)}% ${t( "complete" )}:${percentageComplete.toFixed(0)}% ${togo})`}</Typography>
    </Breadcrumbs>
  )} ;

  const image = quiz.imageFile[0].childImageSharp.fluid;

  return (
    <React.Fragment>
      <AlbumLayout
        title={`Atheos ${quiz.name}`}
        description={quiz.description}
        fluid={image}
        language={quiz.language}
        pagePath={pagePath}
        breadCrumbLinks={_breadCrumbs()}
      >
        {Object.values(quiz.questions).map((question) => {
          return (
            <Grid item key={question.id} xs={12} sm={6} md={6}>
              <Card className={classes.card}>
                {/* https://www.reddit.com/r/gatsbyjs/comments/hlh9rz/gatsby_image_and_material_ui/fx2cjna/ */}
                <CardContent className={classes.cardContent}>
                  {question.imageFile && (
                    <Img
                      imgStyle={{ padding: "8px" }}
                      fluid={question.imageFile[0].childImageSharp.fluid}
                    />
                  )}
                  <Typography>{question.prefix}</Typography>
                  <Typography gutterBottom variant="h5" component="h2">
                    {question.question}
                  </Typography>
                </CardContent>

                <CardActions style={{ flexDirection: "column" }}>
                  <AnswerAccordion
                    question={question}
                    answers={answers}
                    upsertAnswer={_upsertAnswer}
                  />
                  {showComments === question.id && (
                    <Comment
                      question={question}
                      onClose={onCommentDialogClose}
                    />
                  )}
                  <IconButton
                    color="secondary"
                    onClick={(e) => setShowComments(question.id)}
                  >
                    <CommentIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
        <Grid item key="breadCrumbs">
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              {_breadCrumbs()}
            </CardContent>
          </Card>
        </Grid>
      </AlbumLayout>
    </React.Fragment>
  );
};

export default Quiz;